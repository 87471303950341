import { Fragment, useEffect, useReducer } from "react";

import { Checkbox } from "./Checkbox";
import { DateInput } from "./DateInput";
import { Graph } from "./Graph";
import { loadSource } from "./source";
import { initState, reduce, Actions } from "./state";
import { merge, isEmpty } from "./support";

import ripeLogo from "../images/ripe-ncc.svg";

export const App = () => {
    const [ state, dispatch ] = useReducer(reduce, initState);

    useEffect(() => {(async () => {
        dispatch (Actions.loading);
        try {
            const sourceData = (await Promise.all(
                Object.entries(state.sources).map(
                    async ([name, source]) => ({[name]: await loadSource(name, source.url)})
                )
            )).reduce(merge);
            dispatch (Actions.sourceDataLoaded (sourceData));
          } catch (e) {
            dispatch (Actions.reportError (e));
        }
    })();}, []);

    return (
        <article role="page">
          <header>
            <div className="logo">
              <a href="#">
                <img src={ripeLogo} alt="RIPE NCC"></img>
              </a>
            </div>
            <h1 className="application">Resource Certification Stats</h1>
          </header>
          <div className="content-wrapper">
            <aside role="filter">
              <nav className="card">
                { Object.entries(state.sources).map(([name, source]) =>
                    <Checkbox key={name}
                              label={name}
                              checked={source.enabled}
                              onChange={() => dispatch(Actions.toggleSource(name, !source.enabled))} />
                )}
              </nav>
              <nav className="card">
                <DateInput label="From" value={state.period.from} onChange={(d) => dispatch(Actions.filterFrom(d))} />
                <DateInput label="Until" value={state.period.until} onChange={(d) => dispatch(Actions.filterUntil(d))} />
              </nav>
            </aside>
            <main>
              { (state.error != null) && (<div className="card danger">{state.error}</div>) }
              { state.loading && (
                  <div className="overlay">
                    <div className="loading">
                      <div className="circle"></div>
                    </div>
                  </div>
              )}
              { !isEmpty(state.data) && (
                  <Fragment>
                    <Graph title="Number of Certificates"
                           attr="certs"
                           attrFriendly="Certificates"
                           data={state.data}
                    />
                    <Graph title="Number of ROAs"
                           attr="roas"
                           attrFriendly="ROAs"
                           data={state.data}
                    />
                    <Graph title="ASNs in ROAs"
                           attr="roa-asn"
                           attrFriendly="ASNs"
                           data={state.data}
                    />
                    <Graph title="IPv4 prefixes in ROAs"
                           attr="roa-v4"
                           attrFriendly="IPv4 Prefixes"
                           data={state.data}
                    />
                    <Graph title="IPv4 address space in ROAs (/24s)"
                           attr="roa-v4u"
                           attrFriendly="IPv4 /24"
                           data={state.data}
                    />
                    <Graph title="IPv6 prefixes in ROAs"
                           attr="roa-v6"
                           attrFriendly="IPv6 prefixes"
                           data={state.data}
                    />
                    <Graph title="IPv6 address space in ROAs (/48s)"
                           attr="roa-v6u"
                           attrFriendly="IPv6 /48"
                           data={state.data}
                    />
                  </Fragment>
              ) }
            </main>
          </div>
          <footer>
            These graphs are rendered from statistics collected daily by the
            RIPE NCC. This service runs on a best-effort basis. If you would
            like to get in touch, please contact <a href="mailto:rpki@ripe.net">rpki@ripe.net</a>.
          </footer>
        </article>
    );
};
